@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -webkit-outline: none;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
  }

  body {
    @apply bg-bg-bgGrey dark:bg-bg-body;
  }

  .btn-primary-sm {
    @apply cursor-pointer items-center rounded-full bg-btn-c1 px-[5px] py-[6px] text-xs font-medium leading-4 text-white-1 duration-100 hover:opacity-80;
  }
  .btn-secondary-sm {
    @apply cursor-pointer items-center rounded-full border border-gray-300 px-[5px] py-[5px] text-xs font-medium leading-4 text-textGray-900 duration-100 hover:opacity-80 dark:border-white-300 dark:text-white-1;
  }
  .btn-primary {
    @apply h-[40px] w-full cursor-pointer items-center rounded-full border-none bg-btn-c1 px-2 text-sm font-medium text-white-1 duration-100 hover:opacity-80 disabled:bg-colorPrimaryLight disabled:text-colorPrimary;
  }
  .btn-secondary {
    @apply h-[40px] w-full cursor-pointer items-center rounded-full border border-bg-gray-300 px-2 text-sm font-medium text-textGray-900 duration-100 hover:opacity-80 disabled:text-textGray-400 dark:text-white-1 dark:disabled:text-white-400;
  }
  .card {
    @apply overflow-hidden rounded-base bg-white-1 shadow-lg dark:bg-card-c1;
  }
  .page-container {
    @apply mx-auto min-h-full max-w-container px-4 md:px-8 lg:px-16;
  }
  .page-container-xl {
    @apply m-auto min-h-full w-full max-w-containerXL px-4 2xl:px-0;
  }
  .text900 {
    @apply text-textGray-900 dark:text-white-900;
  }
  .text700 {
    @apply text-textGray-700 dark:text-white-700;
  }
  .text600 {
    @apply text-textGray-600 dark:text-white-600;
  }
  .text500 {
    @apply text-textGray-500 dark:text-white-500;
  }
  .text400 {
    @apply text-textGray-400 dark:text-white-400;
  }
  .border300 {
    @apply border-gray-300 dark:border-white-300;
  }
  .border200 {
    @apply border-gray-200 dark:border-white-200;
  }
  .bg900 {
    @apply bg-white-1 dark:bg-card-c1;
  }
  .bg300 {
    @apply bg-gray-300 dark:bg-white-300;
  }
  .bg200 {
    @apply bg-gray-200 dark:bg-white-200;
  }
  .bg100 {
    @apply bg-gray-100 dark:bg-white-100;
  }
  .stroke1 {
    @apply stroke-icon-icon dark:stroke-white-1;
  }
  .fill1 {
    @apply fill-icon-icon dark:fill-white-1;
  }
  .btn-link {
    @apply text-sm font-medium text-colorPrimary;
  }
}

.tt {
  color: white;
}

.todo {
  color: red;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  /* background: gray; */
  /* overflow: hidden; */
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #ff6700;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 3px #0f1c2e;
  background: #ff6700;
}

#squid-widget #squid-submit-swap-btn {
  max-height: 48px;
  min-height: 48px;
}

#squid-widget #squid-submit-swap-btn span {
  color: white;
}

#squid-widget #squid-dropdown-btn button {
  border: 1px solid gray;
  box-shadow: none;
}
